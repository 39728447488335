import {configureStore, ThunkAction, Action, createAsyncThunk as createAsyncThunkRedux} from '@reduxjs/toolkit';
import {problemsSlice} from "../redux/problemsSlice";
import {submissionsSlice} from "../redux/submissionsSlice";
import {userSlice} from "../redux/userSlice";
import {settingsSlice} from "../redux/settingsSlice";
import {paymentsSlice} from '../redux/paymentsSlice';
import {promoRoadmapJsSlice} from "../redux/promoRoadmapJs";

export function createStore() {
  return configureStore({
    reducer: {
      problems: problemsSlice.reducer,
      user: userSlice.reducer,
      settings: settingsSlice.reducer,
      submissions: submissionsSlice.reducer,
      payments: paymentsSlice.reducer,
      promoRoadmapJs: promoRoadmapJsSlice.reducer,
    },
  });
}

export const store = createStore();

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
